import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from '@material-ui/core/TextField';
import React from "react";
import FormComponent from "../../../../classes/FormComponent";



class ThingsBoardIntegrationForm extends FormComponent {
  onChange(e) {
    super.onChange(e);
    this.props.onChange(this.state.object);
  }

  render() {
    if (this.state.object === undefined) {
      return null;
    }

    return(
      <FormControl fullWidth margin="normal">
        <br />
        <h4>ThingsBoard.io Integration Configuration</h4>
        <TextField
          id="server"
          label="ThingsBoard.io server"
          placeholder="http://host:port"
          value={this.state.object.server || ""}
          onChange={this.onChange}
          margin="normal"
          required
          fullWidth
        />
        <FormHelperText>
          Each device must have a 'ThingsBoardAccessToken' variable assigned. This access-token is generated by ThingsBoard.
        </FormHelperText>
      </FormControl>
    );
  }
}

export default ThingsBoardIntegrationForm;
