import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Row } from 'reactstrap';
import TitleBar from "../../../components/TitleBar";
import i18n, { packageNS } from '../../../i18n';
import DataDash from "../../../components/DataDash"


class RedirectToMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onSubmit = (e, data) => {
    e.preventDefault();
  }

  render() {

    return (
      <React.Fragment>
        <TitleBar>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link>
                {i18n.t(`${packageNS}:m2m_redirect.link`)}             
              </Link>
            </BreadcrumbItem>
          </Breadcrumb>
        </TitleBar>
        <Row>
          <DataDash />
        </Row>
      </React.Fragment>
    );
  }
}



export default withRouter(RedirectToMobile);
