import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Card, Row, Container } from 'reactstrap';
import OtpInput from 'react-otp-input';
import TitleBar from "../../components/TitleBar";
import localStyles from "../common/Style";
import SessionStore from "../../stores/SessionStore";
import i18n, { packageNS } from "../../i18n";
import Loader from "../../components/Loader";

const styles = {
    ...localStyles
};

class Login2FA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: null
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
    }

    goBack = () => {
        this.props.history.goBack();
    }

    next = async () => {
        let token = this.state.token;

        if (token != null && token.length === 6) {
            this.setState({ loading: true });

            SessionStore.setOTPToken(token);

            SessionStore.login2fa(token, (resp) => {
                this.setState({ loading: false });
                const orgs = SessionStore.getOrganizations();
                if (SessionStore.getToken() && orgs.length > 0) {
                    this.props.history.push("/");
                } else {
                    console.log('User has no organisations. Redirecting to login');
                    this.props.history.push("/login");
                }
            }, (error) => {
                this.setState({ loading: false });
                alert(i18n.t(`${packageNS}:menu.dashboard_2fa.otp_code_undefined`));
            });
        }
    }

    handleChange = (n) => {
        let object = this.state;
        object.token = n;
        this.setState({ object });
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <div className="position-relative">
                    {this.state.loading && <Loader />}
                    <TitleBar>
                    </TitleBar>
                    <Container>
                        <Card className="card-box shadow-sm">
                            <Row className={classes.paddingSpace}>
                                <div className="text-center" style={{ width: '100%' }}>
                                    <h3>{i18n.t(`${packageNS}:menu.dashboard_2fa.2fa_code_label`)}</h3>
                                    <p>{i18n.t(`${packageNS}:menu.dashboard_2fa.enter_2fa_code`)}</p>
                                </div>
                            </Row>
                            <Row className={classes.numLayout && classes.verticalSpace}>
                                <OtpInput
                                    onChange={otp => this.handleChange(otp)}
                                    value={this.state.token}
                                    inputStyle={classes.num}
                                    numInputs={6}
                                    separator={<div style={{ width: '10px' }}></div>}
                                />
                            </Row>
                            <Row>
                                <div className="text-center" style={{ width: '100%' }}>
                                    <p>{i18n.t(`${packageNS}:menu.dashboard_2fa.manage_2fa`)}</p>
                                </div>
                            </Row>
                            <Row className={classes.numLayout && classes.verticalSpace}>
                                <div class="base" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={this.goBack}>
                                    <span style={{ color: 'white', cursor: 'pointer' }}>{i18n.t(`${packageNS}:tr000463`)}</span>
                                </div>
                                <div class="baseRight" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={this.next}>
                                    <span style={{ color: 'white', cursor: 'pointer' }}>{i18n.t(`${packageNS}:menu.registration.next`)}</span>
                                </div>
                            </Row>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>

        );
    }
}

export default withStyles(styles)(withRouter(Login2FA));
