import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SessionStore from "../../stores/SessionStore";
import AdminDashboard from "./Admin";
import UserDashboard from "./User";



class Dashboard extends Component {
    constructor(props) {
        super(props);


        this.state = {
            show2FaFeature: false
        }
    }

    componentDidMount() {
        // TODO - api call to check if user has not enabled the feature
        this.setState({ show2FaFeature: false });//edited 2020-03-11 by Namgyeong
    }

    render() {
        const user = SessionStore.getUser();

        return (<React.Fragment>
           {/*  <div className="position-relative">
                <div className="card-coming-soon-2">
                    <h1 className="title">{i18n.t(`${packageNS}:menu.dashboard.coming_soon`)}</h1>
                </div> */}
                <div style={{ padding: 30 }}>
                    {user.isAdmin ? <AdminDashboard user={user} /> : <UserDashboard user={user} />}
                </div>
            {/* </div> */}
        </React.Fragment>
        );
    }
}

export default withRouter(Dashboard);