import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import VerificationWith2FA from "../common/VerificationWith2FA";

class RegistrationConfirm extends Component {

    render() {
        let token = '';
        if(this.props !== undefined){
            token = this.props.match.params.securityToken;
        }
        
        return (
            <VerificationWith2FA restart={`/registration`} next={`/registration-confirm-steptwo/${token}`}/>
        );
    }
}

export default withRouter(RegistrationConfirm);
