import i18n, { packageNS } from '../i18n';
/*
    password recovery
*/
export const PASSWORD_RECOVERY_DESCRIPTION_001 = i18n.t(`${packageNS}:tr000013`);
export const PASSWORD_RECOVERY_DESCRIPTION_002 = i18n.t(`${packageNS}:tr000015`);

export const PASSWORD_RECOVERY_ERROR_MINIMUM_LENGTH = i18n.t(`${packageNS}:tr000016`);
export const PASSWORD_RECOVERY_ERROR_MISMATCH = i18n.t(`${packageNS}:tr000017`);


